export default function () {
    let formatDate = (date) => {
        let Y = date.getFullYear()
        let M = date.getMonth() < 9 ? "0" + Number(date.getMonth() + 1) : date.getMonth() + 1
        let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
        return (
            Y + "-" + M + "-" + D
        );
    }
    let formatDateTime = (date) => {
        let Y = date.getFullYear()
        let M = date.getMonth() < 9 ? "0" + Number(date.getMonth() + 1) : date.getMonth() + 1
        let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
        let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
        return (
            Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s
        );
    }
    return {
        formatDate,
        formatDateTime
    }
}
